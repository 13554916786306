import styled from "styled-components";

import FigmaPng from "../media/techStackLogos/figma.png";
import CssPng from "../media/techStackLogos/css-3.png";
import HtmlPng from "../media/techStackLogos/html-5.png";
import JsPng from "../media/techStackLogos/js.png";
import ReactSvg from "../media/techStackLogos/react.svg";
import TsPng from "../media/techStackLogos/typescript.png";
import NodePng from "../media/techStackLogos/node-js.png";
import FirebaseSvg from "../media/techStackLogos/firebase.svg";

const triangle1Height = "175px";
const triangle2Height = "160px";

const Box = styled.section`
  width: 100vw;
  height: 500px;
  background-color: black;

  align-items: flex-start;
  margin-bottom: ${triangle2Height};
`;

const Triangle1 = styled.div`
  position: absolute;
  width: 100vw;
  height: ${triangle1Height};

  //triangle1height - 1 px
  top: -174px;

  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 50%, black 0%);
`;
const Content = styled.div`
  position: relative;
  padding-top: 9vh;
  height: 500px;
`;
const P = styled.p`
  font-size: 25px;
  color: white;
  margin: 0;
  margin-bottom: calc(470px - 9vh);

  @media (max-width: 900px) and (max-height: 1200px) {
    position: relative;
    bottom: 60px;
  }
`;

const TechBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  gap: 10px;
`;
const TechLogo = styled.img`
  width: 75px;
  height: auto;

  @media (max-width: 600px) {
    width: 50px;
  }
`;
const TechLogoSvg = styled.object.attrs({ type: "image/svg+xml" })`
  width: 75px;
  height: auto;
  @media (max-width: 600px) {
    width: 50px;
  }
`;
const TechName = styled.p`
  font-size: 30px;
  color: white;
  @media (max-width: 750px) {
    font-size: 20px;
  }
`;

const Triangle2 = styled.div`
  position: relative;
  bottom: 1px;
  right: 9vw;
  height: ${triangle2Height};
  width: 100vw;
  z-index: -1;
  background: linear-gradient(to bottom left, black 50%, white 0);
`;

// Single Logos, made responsive

const Typescript = styled(TechBox)`
  top: 8%;
  left: 48%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    top: 18%;
    left: 16%;
  }
`;
const Javascript = styled(TechBox)`
  top: 27%;
  left: 25%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    top: 38%;
    left: 0;
  }
`;
const Firebase = styled(TechBox)`
  top: 65%;
  left: 28%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    top: 70%;
    left: 12%;
  }
`;
const Node = styled(TechBox)`
  top: 90%;
  left: 45%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
  }
`;
const React = styled(TechBox)`
  top: 40%;
  left: 55%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    left: 45%;
    top: 50%;
  }
  @media (max-width: 600px) {
    left: 56%;
    top: 58%;
  }
`;
const HTML = styled(TechBox)`
  top: 82%;
  left: 74%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
  }

  @media (max-width: 600px) {
    top: 104%;
    left: 70%;
  }
`;
const Figma = styled(TechBox)`
  top: 45%;
  left: 88%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    top: 40%;
    left: 82%;
  }

  @media (max-width: 600px) {
    left: 58%;
    top: 32%;
  }
`;
const Css = styled(TechBox)`
  top: 11%;
  left: 78%;
  //iPad Air Optimization
  @media (max-width: 900px) and (max-height: 1200px) {
    top: 10%;
    left: 65%;
  }

  @media (max-width: 600px) {
    top: 0;
  }
`;

export const TechStackSection = () => {
  return (
    <Box>
      <Triangle1 />
      <Content className="content">
        <P>my tech Stack</P>

        <Typescript>
          <TechLogo src={TsPng} />
          <TechName>Typescript</TechName>
        </Typescript>
        <Javascript>
          <TechLogo src={JsPng} />
          <TechName>Javascript</TechName>
        </Javascript>
        <Firebase>
          <TechLogoSvg data={FirebaseSvg} />
          <TechName>Firebase</TechName>
        </Firebase>
        <Node>
          <TechLogo src={NodePng} />
          <TechName>Node</TechName>
        </Node>
        <React>
          <TechLogoSvg data={ReactSvg} />
          <TechName>React</TechName>
        </React>
        <HTML>
          <TechLogo src={HtmlPng} />
          <TechName>HTML</TechName>
        </HTML>
        <Figma>
          <TechLogo src={FigmaPng} />
          <TechName>Figma</TechName>
        </Figma>
        <Css>
          <TechLogo src={CssPng} />
          <TechName>CSS</TechName>
        </Css>
        <Triangle2 />
      </Content>
    </Box>
  );
};
