import styled from "styled-components";

const LineBox = styled.div`
  position: absolute;
  display: flex;
  gap: 40px;
  top: 400px;
  right: 8vw;

  @media (max-width: 1160px) {
    display: none;
  } ;
`;
const Line = styled.div`
  background-color: white;
  width: 4px;
`;
const Line1 = styled(Line)`
  height: 470px;
`;
const Line2 = styled(Line)`
  height: 400px;
`;
const Line3 = styled(Line)`
  height: 330px;
`;
const Line4 = styled(Line)`
  height: 260px;

  @media (max-width: 1350px) {
    display: none;
  } ;
`;
const Line5 = styled(Line)`
  height: 190px;

  @media (max-width: 1260px) {
    display: none;
  } ;
`;
const Line6 = styled(Line)`
  height: 120px;
  @media (max-width: 1300px) {
    display: none;
  } ;
`;
const Line7 = styled(Line)`
  height: 50px;

  @media (max-width: 1350px) {
    display: none;
  } ;
`;

export const LineDecoration = () => {
  return (
    <LineBox>
      <Line1 />
      <Line2 />
      <Line3 />
      <Line4 />
      <Line5 />
      <Line6 />
      <Line7 />
    </LineBox>
  );
};
