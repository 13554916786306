import React, { useState, useRef } from "react";
import "./App.css";

import { HeroSection } from "./components/heroSection";
import { ProjectsSectionA } from "./components/projectsSectionA";
import { ProjectsSectionB } from "./components/projectsSectionB";
import { AboutMeSection } from "./components/aboutMeSection";
import { SocialSection } from "./components/socialSection";
import { TechStackSection } from "./components/techStackSection";
import { ContactSection } from "./components/contactSection";
import { FooterSection } from "./components/footerSection";

import emailjs from "@emailjs/browser";

function App() {
  //contactForm in the contact Section
  const form = useRef("");
  const [formName, setFormName] = useState("");
  const [formMail, setFormMail] = useState("");
  const [formSubject, setFormSubject] = useState("");
  const [formMessage, setFormMessage] = useState("");

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const formValues = [formName, formMail, formSubject, formMessage];
    if (formValues.some((v) => v === "")) {
      console.log("please fill out all the fields");
      return;
    }

    if (!formMail.includes("@")) {
      console.log("please provide a valid email");
      return;
    }

    emailjs
      .sendForm(
        "service_rctjs0d",
        "template_hhohwaj",
        form.current,
        "ZD0JISWErDtvOJgpA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const Form = {
    formRef: form,
    formName,
    setFormName,
    formMail,
    setFormMail,
    formSubject,
    setFormSubject,
    formMessage,
    setFormMessage,
    onSubmit: handleSubmit,
  };
  return (
    <>
      <HeroSection />
      <ProjectsSectionA />
      <ProjectsSectionB />
      <AboutMeSection />
      <TechStackSection />
      <SocialSection />
      <ContactSection form={Form} />
      <FooterSection />
    </>
  );
}

export default App;
