import styled from "styled-components";

import { LineDecoration } from "./lineDecoration";

const Section = styled.section`
  align-items: flex-start;
`;
const Background = styled.div`
  height: auto;
`;
const Content = styled.div`
  margin-top: 280px;
  height: auto;
`;

const Triangle = styled.div`
  height: 380px;
  width: 100vw;
  background: linear-gradient(to bottom left, white 50%, black 0);

  position: relative;
  top: 1px;
`;
const BlackDiv = styled.div`
  width: 100vw;
  height: 700px;
  background-color: black;
  @media (max-width: 500px) {
    height: 760px;
  }
  @media (max-width: 440px) {
    height: 800px;
  }
  @media (max-width: 380px) {
    height: 880px;
  }
`;
const VerticalLine = styled.div`
  position: absolute;
  top: 280px;
  left: 92px;

  background-color: white;
  width: 4px;
  height: 640px;

  @media (max-width: 1350px) {
    left: 62px;
  }
  @media (max-width: 1000px) {
    left: 32px;
  }
  @media (max-width: 705px) {
    height: 700px;
  }
  @media (max-width: 500px) {
    left: 16px;
    height: 740px;
  }
  @media (max-width: 440px) {
    left: initial;
    right: 20px;
    top: 390px;
    height: 720px;
  }
  @media (max-width: 380px) {
    height: 760px;
  }
`;
const HorizontalLine = styled.div`
  position: absolute;
  top: 920px;
  left: 92px;

  background-color: white;
  width: 860px;
  height: 4px;

  @media (max-width: 1350px) {
    left: 62px;
  }
  @media (max-width: 1000px) {
    left: 32px;
    width: 84vw;
  }
  @media (max-width: 705px) {
    top: 980px;
  }
  @media (max-width: 500px) {
    left: 16px;
    top: 1020px;
  }
  @media (max-width: 440px) {
    left: initial;
    right: 20px;
    top: 1110px;
    width: calc(92vw - 20px);
  }
  @media (max-width: 380px) {
    top: 1150px;
  }
`;

const P = styled.p`
  font-size: 20px;
  color: white;
  margin: 0;
  margin-bottom: 80px;
`;
const ProjectsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
  margin-bottom: 250px;
  @media (max-width: 993px) {
    margin-bottom: 230px;
  }
  @media (max-width: 845px) {
    margin-bottom: 195px;
  }
  @media (max-width: 821px) {
    margin-bottom: 165px;
  }
  @media (max-width: 704px) {
    margin-bottom: 135px;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: white;

  &:visited {
    color: white;
  }
  &:active {
    color: white;
  }
`;
const Project = styled.div`
  width: fit-content;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  @media (pointer: fine) {
    &:hover {
      border-bottom-color: white;
      border-right-color: white;
      cursor: pointer;
    }
  }
`;
const ProjectTitle = styled.h3`
  font-size: 30px;
  margin: 0;
  margin-right: 20px;
  margin-bottom: 18px;
`;
const ProjectDescription = styled.p`
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
`;

export const ProjectsSectionB = () => {
  return (
    <Section>
      <Background className="background">
        <Triangle />
        <BlackDiv />
        <VerticalLine />
        <HorizontalLine />
        <LineDecoration />
      </Background>

      <Content className="content">
        <P>further Projects</P>

        <ProjectsBox>
          <A
            href="https://xn--kraftwerk-urnschtobel-i2b.ch/home.html"
            target="blank"
          >
            <Project>
              <ProjectTitle>
                Hydroelectric power station, Urnäschtobel
              </ProjectTitle>
              <ProjectDescription>
                static website <br />
                making the station more known in the region
              </ProjectDescription>
            </Project>
          </A>

          <A href="https://erneuerb-ar.ch/" target="blank">
            <Project>
              <ProjectTitle>
                Peoples Initiative regarding energy, Appenzell Ausserrhoden
              </ProjectTitle>
              <ProjectDescription>
                static website <br />
                showing who is in favour of the upcoming votation and providing
                arguments
              </ProjectDescription>
            </Project>
          </A>

          <Project>
            <ProjectTitle>
              Introduction to Instagram series &lt;Claudia erklärts&gt;
            </ProjectTitle>
            <ProjectDescription>
              short Video made with React <br />
              client can adjust the text in the video
            </ProjectDescription>
          </Project>
        </ProjectsBox>
      </Content>
    </Section>
  );
};
