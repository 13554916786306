import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import { SectionTitle } from "./general-components/SectionTitle";
import ArrowUpSvg from "../media/arrow-up.svg";

import "./contactSection.css";

const Section = styled.section`
  display: block;
  position: relative;
`;

const SideElement = styled.div`
  position: absolute;
  top: -60px;
  right: 0;
  width: 120px;

  @media (max-width: 1050px) {
    width: 80px;
  }
  @media (max-width: 920px) {
    width: 40px;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;
const TopTriangle = styled.div`
  width: 100%;
  height: 520px;
  background: linear-gradient(to bottom right, black 50%, white 0);
  position: relative;
  top: 1px;
`;
const BottomTriangle = styled.div`
  width: 100%;
  height: 290px;
  background: linear-gradient(to bottom left, white 50%, black 0);
`;
const Triangle = styled.div`
  height: 170px;
  width: 100vw;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 50%, black 0);

  position: relative;
  top: 1px;
`;
const SideTitle = styled(SectionTitle)`
  color: white;

  @media (max-width: 950px) {
    display: none;
  }
`;
const Box = styled.div`
  position: relative;
  width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  width: 700px;
  color: white;

  @media (max-width: 850px) {
    width: 90vw;
  }
`;

const P = styled.p`
  font-size: 30px;
  text-align: center;
  line-height: 1.6;
`;
const PBig = styled.p`
  font-size: 50px;
  text-align: center;
  margin: 33px 0 58px 0;
`;

const formFontSize = "25px";
const bottomMargin = "80px";
const ContactForm = styled.form`
  display: flex;
  gap: 32px;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin-bottom: ${bottomMargin};
`;
const FirstLine = styled.div`
  display: flex;
  gap: 25px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;
const Input = styled.input`
  flex: 1;
  border-radius: 0;
  padding: 12px 20px;
  font-size: ${formFontSize};
  background-color: black;
  border: 1px solid white;
  color: white;
  appearance: none;

  &:focus {
    border-radius: 0;
    padding: 11px 19px;
    border: 2px solid white;
    outline: none;
  }
`;
const Textarea = styled.textarea`
  flex: 1;
  border-radius: 0;
  padding: 12px 20px;
  font-size: ${formFontSize};
  background-color: black;
  border: 1px solid white;
  color: white;
  appearance: none;

  font-family: "Roboto", sans-serif;
  &:focus {
    border-radius: 0;
    padding: 11px 19px;
    border: 2px solid white;
    outline: none;
  }
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const SubmitBtn = styled.button.attrs({ type: "submit" })`
  color: white;
  background-color: black;
  border: 1px solid white;
  padding: 16px 32px;
  font-size: ${formFontSize};
  font-family: "Roboto", sans-serif;

  &:hover {
    padding: 13px 29px;
    border: 4px solid white;
    cursor: pointer;
  }
`;

const ArrowUp = styled.img`
  width: 60px;
`;

type PropsType = {
  form: {
    formRef: any;
    formName: string;
    setFormName: Function;
    formMail: string;
    setFormMail: Function;
    formSubject: string;
    setFormSubject: Function;
    formMessage: string;
    setFormMessage: Function;
    onSubmit: Function;
  };
};
export const ContactSection = (props: PropsType) => {
  const { form } = props;

  return (
    <Section id="contact">
      <Triangle />
      <Box>
        <SideElement>
          <TopTriangle />
          <BottomTriangle />
        </SideElement>

        <Content className="content">
          <SideTitle>contact</SideTitle>
          <P>
            Want to work together? <br />
            You need help with your project?
          </P>
          <PBig>Contact me!</PBig>
          <ContactForm ref={form.formRef}>
            <FirstLine>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                onChange={(e) => form.setFormName(e.target.value)}
                value={form.formName}
              />
              <Input
                type="mail"
                name="mail"
                placeholder="Email"
                onChange={(e) => form.setFormMail(e.target.value)}
                value={form.formMail}
              />
            </FirstLine>
            <Textarea
              placeholder="Subject"
              name="subject"
              onChange={(e) => form.setFormSubject(e.target.value)}
              value={form.formSubject}
            />
            <Textarea
              name="message"
              placeholder="Message"
              rows={8}
              onChange={(e) => form.setFormMessage(e.target.value)}
              value={form.formMessage}
            />
            <SubmitBox>
              <SubmitBtn onClick={(e) => form.onSubmit(e)}>Send</SubmitBtn>
            </SubmitBox>
          </ContactForm>
        </Content>
      </Box>

      <Link
        className="arrow-up-link"
        to="hero-section"
        spy={true}
        smooth={true}
        offset={0}
        duration={2500}
      >
        <ArrowUp src={ArrowUpSvg} />
      </Link>
    </Section>
  );
};
