import styled from "styled-components";

const Section = styled.section`
  background-color: black;
  flex-direction: column;
`;
const Hr = styled.hr`
  width: 100%;
  height: 2px;
  color: white;
  background-color: white;
`;
const Content = styled.div``;
export const FooterSection = () => {
  return (
    <Section>
      <Hr />
      <Content className="content">
        <p>Hallo Welt</p>
      </Content>
    </Section>
  );
};
