import styled from "styled-components";
import { Link } from "react-scroll";
import { SwissMapSvg } from "./general-components/SwissMapSvg";

import expandIcon from "../media/expand-icon.svg";
import pin from "../media/Pin.svg";

import "./heroSection.css";

const Section = styled.section`
  height: 100vh;
`;
const Nav = styled.nav`
  position: absolute;
  top: 10%;
  right: 5%;
  display: flex;

  //iPad Air Optimization
  @media (max-width: 1200px) {
    top: 15%;
  }
  @media (max-width: 550px) {
    top: 18%;
    right: 3%;
  }
`;

const UpperTriangle = styled.div`
  width: 100%;
  height: 25vh;
  background: linear-gradient(to bottom right, white 50%, black 0);
  position: relative;
  top: 1px;
`;
const MiddleBox = styled.div`
  width: 100%;
  height: 35vh;
  background-color: black;
`;
const LowerTriangle = styled.div`
  width: 100%;
  height: 40vh;
  background: linear-gradient(to bottom left, black 50%, white 0);
  position: relative;
  bottom: 1px;
`;
const SideTriangle = styled.div`
  position: absolute;
  top: 30%;
  width: 5.3vw;
  height: 24vh;
`;
const Upper = styled.div`
  width: 100%;
  height: 34%;
  background: linear-gradient(to bottom left, black 50%, white 0);
`;
const Lower = styled.div`
  width: 100%;
  height: 66%;
  background: linear-gradient(to bottom right, white 50%, black 0);
`;

const ExpandIcon = styled.img`
  position: absolute;
  left: calc(50% - 43px);
  bottom: 6%;
  transition: transform 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;

const Content = styled.div`
  height: 82vh;
`;

const Title = styled.h1`
  color: white;
  /* margin-top: 13%; */
  margin-top: 18vh;
  font-size: 72px;
  font-weight: 400;

  @media (max-width: 1200px) {
    font-size: 60px;
  }
  @media (max-width: 900px) {
    font-size: 65px;
  }
  @media (max-width: 950px) {
    font-size: 45px;
  }
  @media (max-width: 400px) {
    font-size: 36px;
  }
  //iPad Air Optimization
`;

const SwissMap = styled.div`
  position: relative;
  bottom: 34vh;
  left: 36vw;

  height: auto;
  width: 50vw;

  //iPad Air Optimization
  @media (max-width: 950px) {
    bottom: 3%;
    left: 18%;
    width: 70vw;
  }

  @media (max-width: 400px) {
    bottom: -4%;
  }
`;

const Location = styled.div`
  position: absolute;
  top: 10%;
  left: 53%;

  @media (max-width: 530px) {
    top: 0;
    left: 50%;
  }
`;
const Pin = styled.img`
  width: 18%;

  @media (max-width: 530px) {
    width: 28%;
  }
`;
const NameTag = styled.p`
  position: relative;
  right: 19px;
  bottom: 8px;
  color: white;
  margin: 0;

  font-size: 34px;

  @media (max-width: 750px) {
    font-size: 28px;
  }
  @media (max-width: 530px) {
  }
`;
export const HeroSection = () => {
  return (
    <Section id="hero-section">
      <Nav>
        <Link
          className="nav-link"
          to="projects"
          spy={true}
          smooth={true}
          offset={0}
          duration={700}
        >
          Projects
        </Link>
        <Link
          className="nav-link"
          to="about-me"
          spy={true}
          smooth={true}
          offset={0}
          duration={1500}
        >
          About me
        </Link>
        <Link
          className="nav-link"
          to="contact"
          spy={true}
          smooth={true}
          offset={0}
          duration={2500}
        >
          Contact
        </Link>
      </Nav>

      <div className="background">
        <UpperTriangle />
        <MiddleBox />
        <LowerTriangle />
        <SideTriangle>
          <Upper />
          <Lower />
        </SideTriangle>
      </div>

      <Content className="content">
        <Title>
          Web Developer <br />
          from Switzerland.
        </Title>

        <SwissMap>
          <SwissMapSvg />
          <Location>
            <Pin src={pin} />
            <NameTag>Zurich</NameTag>
          </Location>
        </SwissMap>
        <Link to="projects" spy={true} smooth={true} offset={0} duration={700}>
          <ExpandIcon src={expandIcon} />
        </Link>
      </Content>
    </Section>
  );
};
