import styled from "styled-components";
import linksDesktop from "../media/links_desktop.png";
import linksMobile from "../media/links_mobile.png";

import designIcon from "../media/design-icon.svg";
import implementationIcon from "../media/implementation-icon.svg";
import cmsIcon from "../media/cms-icon.svg";

import { SectionTitle } from "./general-components/SectionTitle";

const Section = styled.section`
  height: 100vh;
  @media (max-width: 1050px) {
    height: fit-content;
  }
`;
const Content = styled.div`
  height: 82vh;
  @media (max-width: 1050px) {
    height: fit-content;
  }
`;
const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1050px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

const ProjectLink = styled.a`
  display: inline-block;
  padding-right: 40px;
  margin-top: 40px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  text-decoration: none;

  &:hover {
    border-bottom-color: black;
    border-right-color: black;
  }
  &:visited {
    color: initial;
  }
`;
// general component "SectionTitle"
const Subtitle = styled.h2`
  font-size: 50px;
  font-weight: 300;
  margin-top: 0;
`;
const Description = styled.p`
  margin: 0;
  font-size: 20px;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const PdWrapper = styled.div`
  display: flex;
  @media (max-width: 951px) {
    justify-content: center;
  }
`;
const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  margin-top: 60px;

  @media (max-width: 1050px) {
    margin-bottom: 80px;
  }
  @media (max-width: 1050px) and (min-width: 952px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (max-width: 951px) {
    width: fit-content;
  }
`;
const Detail = styled.div`
  display: flex;
  gap: 50px;
  @media (max-width: 1050px) and (min-width: 952px) {
    gap: 20px;

    &:last-child {
      width: 100%;
      justify-content: center;
    }
  }
`;
const DetailIcon = styled.img`
  width: 70px;
`;
const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const DetailTitle = styled.h3`
  font-size: 30px;
  font-weight: 400;
  margin: 0;
`;
const DetailDescription = styled.p`
  font-size: 20px;
  margin: 0;
`;

const LinkBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

const Right = styled.div`
  display: flex;
  overflow: visible;
  height: 700px;
  gap: 32px;

  @media (max-width: 1050px) {
    justify-content: center;
    gap: 60px;
  }
  @media (max-width: 900px) {
    height: 580px;
  }
  @media (max-width: 800px) {
    height: 690px;
  }
  @media (max-width: 650px) {
    height: 580px;
    gap: 40px;
  }
  @media (max-width: 500px) {
    height: 480px;
    gap: 20px;
  }
  @media (max-width: 400px) {
    height: 400px;
  }
`;

const LinksImage = styled.img`
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.8);
  }
`;
const LinksDesktop = styled(LinksImage)`
  width: 20.8vw;
  height: auto;

  @media (max-width: 1050px) {
    width: 30vw;
  }
  @media (max-width: 800px) {
    width: 36vw;
  }
  @media (max-width: 500px) {
    width: 44vw;
  }
  @media (max-width: 400px) {
    width: 48vw;
  }
`;
const LinksMobile = styled(LinksImage)`
  width: 13.8vw;
  height: auto;
  @media (max-width: 1050px) {
    width: 20vw;
  }
  @media (max-width: 800px) {
    width: 26vw;
  }
  @media (max-width: 500px) {
    width: 32vw;
  }
  @media (max-width: 400px) {
    width: 34vw;
  }
`;

const LinksLink = styled.a`
  font-size: 40px;
  font-weight: 300;
  text-decoration: none;

  &:visited {
    color: black;
  }
  &:hover {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media (pointer: fine) {
    display: none;
  }
  @media (min-width: 1050px) {
    display: none;
  }
`;

export const ProjectsSectionA = () => {
  return (
    <Section id="projects">
      <Content className="content">
        <SectionTitle>projects</SectionTitle>
        <MainContent>
          <div className="left">
            <ProjectLink href="https://links-sg.ch/" target="blank">
              <Subtitle>Links Magazin</Subtitle>
              <Description>
                Web application for the party magazin LINKS
              </Description>
              <Description>Social Democrats, St.Gallen (SP SG)</Description>
            </ProjectLink>

            <PdWrapper>
              <ProjectDetails>
                <Detail>
                  <DetailIcon src={designIcon} />
                  <DetailContent>
                    <DetailTitle>Design</DetailTitle>
                    <DetailDescription>
                      Prototyping and Design in Figma
                    </DetailDescription>
                  </DetailContent>
                </Detail>

                <Detail>
                  <DetailIcon src={implementationIcon} />
                  <DetailContent>
                    <DetailTitle>Implementation</DetailTitle>
                    <DetailDescription>
                      Frontend built in React <br />
                      Backend as Firebase service
                    </DetailDescription>
                  </DetailContent>
                </Detail>

                <Detail>
                  <DetailIcon src={cmsIcon} />
                  <DetailContent>
                    <DetailTitle>Content-Management-System</DetailTitle>
                    <DetailDescription>
                      custom React CMS, so the client can decide <br />
                      about the content on the webage
                    </DetailDescription>
                  </DetailContent>
                </Detail>
              </ProjectDetails>
            </PdWrapper>
            <LinkBox>
              <LinksLink href="https://links-sg.ch/" target="blank">
                links-sg.ch
              </LinksLink>
            </LinkBox>
          </div>
          <Right>
            <a href="https://links-sg.ch/" target="blank">
              <LinksDesktop src={linksDesktop} />
            </a>
            <a href="https://links-sg.ch/" target="blank">
              {" "}
              <LinksMobile src={linksMobile} />
            </a>
          </Right>
        </MainContent>
      </Content>
    </Section>
  );
};
