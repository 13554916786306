import styled from "styled-components";

import InstaSvg from "../media/instagram.svg";
import WhatsappSvg from "../media/whatsapp.svg";
import MailSvg from "../media/mail2.svg";

const Content = styled.div`
  height: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 290px;
  }
  @media (max-width: 400px) {
    height: 300px;
  }
`;
const Left = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const P = styled.p`
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const POnlyBigScreens = styled(P)`
  @media (max-width: 640px) {
    display: none;
  }
`;
const POnlySmallScreens = styled(P)`
  @media (min-width: 640px) {
    display: none;
  }
`;

const A = styled.a`
  text-decoration: none;
  color: black;
  &:visited {
    color: black;
  }
`;
const SocialP = styled.p`
  font-size: 30px;

  @media (max-width: 1050px) {
    font-size: 26px;
  }
  @media (max-width: 950px) {
    font-size: 20px;
  }
`;
const SVG = styled.object.attrs({ type: "image/svg+xml" })`
  width: 60px;
  pointer-events: none;

  @media (max-width: 1050px) {
    width: 45px;
  }
  @media (max-width: 950px) {
    width: 30px;
  }
`;
const Right = styled.div`
  text-align: right;
  &:first-child {
    border: 2px solid red;
    flex-direction: row-reverse;
  }

  @media (max-width: 640px) {
    text-align: left;
  }
`;
const ContactBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;

  gap: 16px;
  border: 1px solid white;

  transition: box-shadow 0.8s;
  /* box-shadow: 10px 10px 7px 0px rgba(0, 0, 0, 0);
  &:hover {
    box-shadow: 10px 10px 7px 1px rgba(0, 0, 0, 0.2);
  } */
`;
const SpecialContactBox = styled(ContactBox)`
  @media (max-width: 640px) {
    flex-direction: row-reverse;
  }
`;

const predefinedWhatsappText =
  "Hello%20Dominic,%20I'd%20like%20to%20realise%20a%20project%20with%20you";
export const SocialSection = () => {
  return (
    <section>
      <Content className="content">
        <Left>
          <P>Hit me up!</P>
          <A
            href={`https://wa.me/41787109394?text=${predefinedWhatsappText}`}
            target="blank"
          ></A>
          <A href="mailto:dominic.tobler.ch@gmail.com">
            <ContactBox>
              <SVG data={MailSvg} />
              <SocialP>dominic.tobler.ch@gmail.com</SocialP>
            </ContactBox>
          </A>
          <POnlyBigScreens></POnlyBigScreens>
        </Left>

        <Right>
          <A href="https://www.instagram.com/dominic.tobler/" target="blank">
            <SpecialContactBox>
              <SocialP>dominic.tobler</SocialP>

              <SVG data={InstaSvg} />
            </SpecialContactBox>
          </A>
          <POnlySmallScreens>
            or use the contact form right below
          </POnlySmallScreens>
        </Right>
      </Content>
    </section>
  );
};
