import styled from "styled-components";
import DominicPng from "../media/dominic.jpg";
import { SectionTitle } from "./general-components/SectionTitle";

const Section = styled.section`
  margin-top: 5px;
`;

const Content = styled.div`
  padding: 8vh 0;
  margin-bottom: 54px;
`;
const PortraitBox = styled.div`
  //iPad Air Optimization
`;
const MainContent = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Portrait = styled.img`
  position: absolute;
  top: -2px;
  right: 0;
  z-index: -100;

  width: 36.7vw;

  @media (max-width: 600px) {
    position: initial;
    width: 60vw;
  }
  @media (max-width: 450px) {
    width: 80vw;
  }
`;

const TextBox = styled.div`
  margin-top: 100px;
`;
const P = styled.p`
  font-size: 25px;
  @media (max-width: 1150px) and (min-width: 601px) {
    margin-right: 33vw;
  }
`;

export const AboutMeSection = () => {
  return (
    <Section id="about-me">
      <Content className="content">
        <SectionTitle>about me</SectionTitle>
        <MainContent>
          <PortraitBox>
            <Portrait src={DominicPng} />
          </PortraitBox>

          <TextBox>
            <P>
              Hi, my name is Dominic. <br />I am a freelancer and student.
            </P>
            <P>
              Currently at the University of Zurich, doing my bachelors <br />{" "}
              in computer science with a minor in political science. <br />
            </P>
            <P>
              The big challenge of our century is Climate Change. <br />
              We need to end our reliance on fossil fuels. In order to <br /> do
              so, we have to build up a new energy system based <br /> on
              renewables.
              <br />
            </P>
            <P>
              I am very interested in solving problems and building <br />{" "}
              things. <br />
              An important technology to support the energy transition <br /> is
              the smart grid. It helps to balance the supply and <br />
              demand of electricity and gets powerful when features <br /> like
              the bi-directional charging of electric vehicles are <br />{" "}
              incorporated.
              <br />
            </P>
          </TextBox>
        </MainContent>
      </Content>
    </Section>
  );
};
