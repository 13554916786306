import styled from "styled-components";

export const SectionTitle = styled.p`
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
`;
/*
export const SectionTitle = styled.p`
  position: absolute;
  top: 30px;
  left: 60px;
  font-size: 20px;

  @media (max-width: 1050px) {
    top: 10px;
    left: 40px;
  }
  @media (max-width: 700px) {
    top: 0;
    left: 10px;
  }
`;
*/
